import {
	initFacilityCaro,
	initFacilityMap,
	initFacilityNav,
	initFacilityUnitFilters,
	initFacilityInfoToggle,
	initFacilityFilterToggle,
	initGoToSize

} from './facility';
import { contactSubmit, initContactMap } from './contact.js';
import { navToggle } from './header.js';
import { sizeGuide } from './size_guide.js';
import { initIndexMap } from './index.js';

navToggle();

if (document.getElementById('home_email_form')) {
	contactSubmit({
		elementSelector: '#home_email_form',
		required: ['email']
	});
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

//Accordion styling for Tips
if (document.getElementById('tips_items_wrap')) {
	setTimeout(function(){
		document.getElementsByClassName('tip_item')[0].classList.add('active');
	}, 500);
	const helpItems = document.querySelectorAll('.tip_item');
	document.getElementById('tips_items_wrap').addEventListener('click', function(e) {
		if(e.target && e.target.classList.contains("tip_head_wrap")) {
			let el = e.target.parentElement;
			if(el.classList.contains('active')) {
				el.classList.remove('active');
			}
			else {
				helpItems.forEach(i => {
					i.classList.remove('active');
				});
				el.classList.add('active');
			}
		}
	});
}

if (document.querySelector('#home_map')) {
	initIndexMap();
}

if (document.querySelector('#contact_map')) {
	initContactMap();
}
	
if (document.querySelector('#facility_carousel')) {
	initFacilityCaro();
}

if (document.querySelector('#facility_map')) {
	initFacilityMap();
}

if (document.querySelector('#facility_nav')) {
	initFacilityNav();
}

if (document.querySelector('#facility_rates')) {
	initGoToSize();
}

if (document.querySelector('#facility_filters')) {
	initFacilityUnitFilters();
}

if (document.querySelector('#facility_info_toggle')) {
	initFacilityInfoToggle();
}

if (document.querySelector('#facility_filter_toggle')) {
	initFacilityFilterToggle();
}

if (document.querySelector('#size_guide_include')) {
	sizeGuide();
}