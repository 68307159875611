import {AutomatitCarousel} from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper';

export const initFacilityCaro = () => {
	const facilityCaro = AutomatitCarousel({
		element: document.querySelector('#facility_carousel'),
		imagesAsBackgrounds: true,
		images: window.facilityImages,
		showDots: false,
		useChevrons: false,
		intervalTiming: window.carouselTiming
	});

	document.getElementById('facility_image_thumbs').addEventListener('click', (e) => {
		if(e.target && e.target.nodeName === "LI"){
			facilityCaro.slideTo(e.target.getAttribute('slide'));
		}
	});
};

export const initFacilityMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#facility_map'),
				locationElementSelector: '#facility_map',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `<a ${(el.hasAttribute('blank'))?'target="_blank"':''} href="${el.getAttribute('link')}" class="map_pin"><img src="/static/images/icons/map_marker_icon_blue.svg" alt="Visit Facility" /></a>`
					};
				}
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(15);
			});
		});
};

export const initFacilityNav = () => {
	document.getElementById('facility_nav').addEventListener('click', (e) => {
		if (e.target && e.target.nodeName === "DIV") {
			// eslint-disable-next-line no-undef
			Velocity(document.querySelector(e.target.getAttribute('link')), 'scroll');
		}
	});
};

export const initFacilityUnitFilters = () => {
	const noUnits = document.getElementById('no_units');
	const units = document.querySelectorAll('.unit_row');
	document.getElementById('facility_filters').addEventListener('click', (e) => {
		if(e.target && e.target.nodeName === "LI"){
			let selectedSize = e.target.getAttribute('size');
			let shownUnits = 0;
			let oldSelected = document.querySelector('.filter[selected]');

			if (oldSelected) {
				oldSelected.removeAttribute('selected');
			}

			if (selectedSize === 'all' || e.target.hasAttribute('selected')) {
				e.target.removeAttribute('selected');
				shownUnits = 1;
				[].forEach.call(units, (unit) => {
					unit.style.display = 'flex';
				});
			}else{
				e.target.setAttribute('selected', "");
				[].forEach.call(units, (unit) => {
					if(unit.getAttribute('size') !== selectedSize){
						unit.style.display = 'none';
					}else{
						unit.style.display = 'flex';
						shownUnits++;
					}
				});
			}
			noUnits.style.display = (shownUnits)?"none":"block";
		}
	});
};

export const initFacilityInfoToggle = () => {
	const facilityInfoToggle = document.getElementById('facility_info_toggle');
	const facilityTop = document.getElementById('facility_top');
	facilityInfoToggle.addEventListener('click', () => {
		facilityInfoToggle.setAttribute('state', (facilityInfoToggle.getAttribute('state') === 'open') ? 'closed' : 'open');
		facilityTop.setAttribute('state', (facilityTop.getAttribute('state') === 'open') ? 'closed' : 'open');
	});
};

export const initFacilityFilterToggle = () => {
	const facilityFilterToggle = document.getElementById('facility_filter_toggle');
	const facilityFilter = document.getElementById('facility_filters');
	facilityFilterToggle.addEventListener('click', () => {
		facilityFilterToggle.setAttribute('state', (facilityFilterToggle.getAttribute('state') === 'open') ? 'closed' : 'open');
		facilityFilter.setAttribute('state', (facilityFilter.getAttribute('state') === 'open') ? 'closed' : 'open');
	});
};

export const initGoToSize = () => {
	const query = window.location.search.substring(1);
	const vars = query.split("&");
	let query_string = {};
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split("=");
		if (typeof query_string[pair[0]] === "undefined") {
			query_string[pair[0]] = decodeURIComponent(pair[1]);
		} else if (typeof query_string[pair[0]] === "string") {
			let arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
			query_string[pair[0]] = arr;
		} else {
			query_string[pair[0]].push(decodeURIComponent(pair[1]));
		}
	}

	if (query_string.size !== undefined) {
		// eslint-disable-next-line no-undef
		Velocity(document.querySelector('#facility_filters'), 'scroll', {
			complete: () => {document.querySelector('#facility_filters .filter[size='+query_string.size+']').click();}
		});
	}
};