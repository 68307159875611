import {
	getMapsHelper
} from './util/mapsHelper';

export const initIndexMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#home_map'),
				locationElementSelector: '#home_map',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `<a ${(el.hasAttribute('blank'))?'target="_blank"':''} href="${el.getAttribute('link')}" class="map_pin"><img src="/static/images/icons/map_marker_icon_blue.svg" alt="Visit Facility" /></a>`
					};
				}
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(15);
			});
		});
};