import {AutomatitCarousel} from './automatit_carousel.m';

export const sizeGuide = () => {
	let allowSlide = true;

	const sizeGuideCaro = AutomatitCarousel({
		element: document.getElementById('size_guide_caro'),
		intervalTiming: false,
		useChevrons: false,
		showDots: false,
		afterSlide: () => {allowSlide = true;}
	});
    
	const selectBox = document.getElementById('size_guide_select');
	selectBox.addEventListener('click', (e) => {
		if(allowSlide){
			if (e.target && e.target.classList.contains('size_guide_selection')) {
				allowSlide = false;
				if(selectBox.hasAttribute('open')){
					selectBox.removeAttribute('open');
					document.querySelector('.mobile_selected').innerHTML = e.target.innerHTML;
				}
				let oldSelection = document.querySelector('.size_guide_selection[selected]');
				if(oldSelection){
					oldSelection.removeAttribute('selected');
				}
				e.target.setAttribute('selected', '');
				sizeGuideCaro.slideTo(e.target.getAttribute('size'));
			}else{
				selectBox.setAttribute('open', '');
			}
		}
	});
};